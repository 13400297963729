var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Articles SEO "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{ref:"form",staticClass:"mt-2",staticStyle:{"width":"100%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Type","label-for":"blog-edit-category"}},[_c('validation-provider',{attrs:{"name":"Show In","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","options":_vm.showList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getPost(_vm.addCourseForm.type)}},model:{value:(_vm.addCourseForm.type),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "type", $$v)},expression:"addCourseForm.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select Article / News","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-caيtegory","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.articleList,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getPostSeo(_vm.addCourseForm.article)}},model:{value:(_vm.addCourseForm.article),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "article", $$v)},expression:"addCourseForm.article"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Title","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.title),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "title", $$v)},expression:"addCourseForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Description","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.addCourseForm.description),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "description", $$v)},expression:"addCourseForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Keywords","label-for":"blog-content"}},[_c('b-form-tags',{model:{value:(_vm.addCourseForm.keyword),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "keyword", $$v)},expression:"addCourseForm.keyword"}})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1),_c('b-row',{staticStyle:{"width":"100%"}},[_c('blog',{ref:"table"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }