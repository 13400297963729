<template>
  <b-card title="SEO Blog">
    <b-tabs>
      <b-tab>
        <template active #title>
          <feather-icon icon="FolderIcon" />
          <span>Articles SEO</span>
        </template>

       <ArticlesSEO></ArticlesSEO>
      </b-tab>
      <!-- <b-tab  @click="$router.push('/seo-course/seo-online-course')"  >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Online Course</span>
        </template>
        <router-view></router-view>

      </b-tab>
       <b-tab @click="$router.push('/seo-course/seo-course-in-city')" >
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span> Course In City</span>
        </template>
        <router-view></router-view>

      </b-tab>
     
      -->
     
    </b-tabs>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab,BCard, BCardText } from 'bootstrap-vue'
import ArticlesSEO from './ArticlesSEO.vue';

// import CategoriesList from './categoriesList.vue';
// import AddCategory from './AddCategory.vue';


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    ArticlesSEO,
  
},
  data() {
    return {
   
    }
  },
}
</script>

